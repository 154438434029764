  import { useEffect, useState } from "react";
  import { useData } from "../../DataContext";
  import { useFormData } from "../../postContext";

  function DeviceInfo(){

    const[data, setdata] = useState([])
    const[hwVer, setHWVer] = useState("")
    const[swVer, setSWVer] = useState("")
    const[proVer, setProVer] = useState("")
    const[pubVer, setPubVer] = useState("")
    const[sn, setSN] = useState("")

    const {fetchedData} = useData()
    const { formData, updateFormData } = useFormData();

    useEffect(() => {
      setdata(fetchedData)
      if(data && data.appSettings){
        setHWVer(data.appSettings.devInfo.hwVer)
        setSWVer(data.appSettings.devInfo.swVer)
        setProVer(data.appSettings.devInfo.provisioningVer)
        setPubVer(data.appSettings.devInfo.publisherVer)
        setSN(data.appSettings.devInfo.serialNo)
      }
    }, [data, fetchedData])

    const handleHWVerChange = (e) => {
      setHWVer(e.target.value)
    
      //set configurations
      const newValue = e.target.value;
      updateFormData('hwVer', newValue);
    }
    
    const handleSWVerChange = (e) => {
      setSWVer(e.target.value)

      //set configurations
      const newValue = e.target.value;
      updateFormData('swVer', newValue);
    }
    
    const handleProVerChange = (e) => {
      setProVer(e.target.value)

      //set configurations
      const newValue = e.target.value;
      updateFormData('provisioningVer', newValue);
    }
    
    const handlePubVerChange = (e) => {
      setPubVer(e.target.value)
      
      //set configurations
      const newValue = e.target.value;
      updateFormData('publisherVer', newValue);
    }
    
    const handleSNChange = (e) => {
        setSN(e.target.value)
      
        //set configurations
        const newValue = e.target.value;
        updateFormData('serialNo', newValue);
      }
      
      // const handleSNChange = (e) => {
      //   setSN(e.target.value)
        
      //   //set configurations
      //   const newValue = e.target.value;
      //   updateFormData('serialNo', newValue);
      // }

    return (
        <>
          <div className="deviceInfoConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/5">Device Info Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="deviceInfoContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">HWVer:</label>
      <input type="number" name="hwVer" placeholder="HWVer" value={hwVer} onChange={handleHWVerChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">SWVer:</label>
      <input type="number" name="swVer" placeholder="SWVer" value={swVer} onChange={handleSWVerChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">ProVer:</label>
      <input type="number" name="provisioningVer" placeholder="ProVer" value={proVer} onChange={handleProVerChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">PubVer:</label>
      <input type="number" name="publisherVer" placeholder="PubVer" value={pubVer} onChange={handlePubVerChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">SN:</label>
      <input type="text" name="serialNo" placeholder="SN" value={sn} onChange={handleSNChange} className="block p-2 border rounded" />
    </div>
  </div>
</div>

        </>
      );
  }

  export default DeviceInfo;