import { useState, useEffect } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";

function ImageConfig(){

  const[data, setData] = useState([])
  const[flip, setFlip] = useState(false)
  const[mirror, setMirror] = useState(false)
  const[wdr, setWDR] = useState("")
  
  const{fetchedData} = useData()
  
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setFlip(data.appSettings.imageCfg.flip)
      setMirror(data.appSettings.imageCfg.mirror)
      setWDR(data.appSettings.imageCfg.wdr)
    }
  },[setFlip, setMirror, fetchedData, data])


  const handleFlipChange = () => {
    const newValue = !flip;
    setFlip(newValue);
    updateFormData('flip', newValue ? 1 : 0);
    console.log(newValue)
  }
  
  const handleMirrorChange = () => {
    const newValue = !mirror;
    setMirror(newValue);
    updateFormData('mirror', newValue ? 1 : 0);
  }
  
  const handleWDRChange = (e) => {
    setWDR(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('wdr', newValue);
  }

    return (
      <>
        <div className="imageConfigs w-full p-10">
          <div className="mainHeadingDiv flex items-center gap-4">
            <h1 className="text-lg font-medium">Image Configurations</h1>
            <div className="headingDiv w-full border-t border-lightGray"></div>
          </div>

          <div className="imageConfigContent flex flex-wrap items-center gap-10 pt-4">
            <div className="relative z-1 flex items-center">
              <span className="mr-2">Flip: </span>
              <label className="flex items-center rounded-full cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={flip}
                  onChange={handleFlipChange}
                  name="flip"
                />
                <div
                  className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                    flip ? "bg-blue-400" : "bg-gray-400"
                  }`}
                >
                  <div
                    className={`h-4 w-4 rounded-full bg-white transform ${
                      flip ? "translate-x-4" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </label>
            </div>
            <div className="relative z-1 flex items-center">
              <span className="mr-2">Mirror: </span>
              <label className="flex items-center rounded-full cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={mirror}
                  onChange={handleMirrorChange}
                  name="mirror"
                />
                <div
                  className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                    mirror ? "bg-blue-400" : "bg-gray-400"
                  }`}
                >
                  <div
                    className={`h-4 w-4 rounded-full bg-white transform ${
                      mirror ? "translate-x-4" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </label>
            </div>

            <div>
              <label htmlFor="wdr">WDR:</label>
              <select
                id="wdrLabel"
                name="wdr"
                value={wdr}
                onChange={handleWDRChange}
                className="ml-2 border rounded p-2"
              >
                <option value="0">Off</option>
                <option value="1">Low</option>
                <option value="2">Mid</option>
                <option value="3">High</option>
                <option value="4">Higher</option>
              </select>
            </div>
          </div>
        </div>
      </>
    );
}

export default ImageConfig;