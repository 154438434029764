import React from "react";
import { useEffect, useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";

function EmailConfigs(){

  const[data, setData] = useState([])
  const[serverName, setServerName] = useState("")
  const[port, setPort] = useState("")
  const[userName, setUserName] = useState("")
  const[password, setPassword] = useState("")
  const[isSSL, setSSL] = useState(false)
  const[isAuth, setAuth] = useState(false)
  const[send, setSend] = useState("")
  const[from, setFrom] = useState("")
  const[attachment, setAttachment] = useState("")
  const[subject, setSubject] = useState("")
  const[text, setText] = useState("")

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();
    
  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setServerName(data.appSettings.emailCfg.emailserver)
      setPort(data.appSettings.emailCfg.emailport)
      setUserName(data.appSettings.emailCfg.emailusername)
      setPassword(data.appSettings.emailCfg.emailpassword)
      setSSL(data.appSettings.emailCfg.ssl)
      setAuth(data.appSettings.emailCfg.logintype)
      setSend(data.appSettings.emailCfg.to)
      setFrom(data.appSettings.emailCfg.from)
      setAttachment(data.appSettings.emailCfg.attachment)
      setSubject(data.appSettings.emailCfg.subject)
      setText(data.appSettings.emailCfg.text)
    }
  }, [setSSL, setAuth, data, fetchedData])

  const handleServerNameChange = (e) => {
    setServerName(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('emailserver', newValue);
  }
  
  const handlePortChange = (e) => {
    setPort(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('emailport', newValue);
  }
  
  const handleUserNameChange = (e) => {
    setUserName(e.target.value)
  
    //set configurations
    const newValue = e.target.value;
    updateFormData('emailusername', newValue);
  }
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('emailpassword', newValue);
  }

  const handleSSLChange = (e) => {
    const newValue = !isSSL;
    setSSL(newValue);
    updateFormData('ssl', newValue ? 1 : 0);
  }
  
  const handleAuthenticationChange = (e) => {
    const newValue = !isAuth;
    setAuth(newValue);
    updateFormData('logintype', newValue ? 1 : 0);
  }
  
  const handleSendChange = (e) => {
    setSend(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('to', newValue);
  }

  const handleFromChange = (e) => {
    setFrom(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('from', newValue);
  }

  const handleSubjectChange = (e) => {
    setSubject(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('subject', newValue);
  }

  const handleTextChange = (e) => {
    setText(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('text', newValue);
  }

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('attachment', newValue);
  }

    return (
      <>
    <div className="emailConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/6">Email Configs</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="emailConfigContent flex items-center flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Server Name:</label>
      <input type="text" name="emailserver" placeholder="Server Name" value={serverName} onChange={handleServerNameChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Port:</label>
      <input type="text" name="emailport" placeholder="Port" value={port} onChange={handlePortChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">User Name:</label>
      <input type="text" name="emailusername" placeholder="User Name" value={userName} onChange={handleUserNameChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Password:</label>
      <input type="text" name="emailpassword" placeholder="Password" value={password} onChange={handlePasswordChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <div className="relative z-1 flex items-center">
                <span className="mr-2">SSL: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isSSL}
                    onChange={handleSSLChange}
                    name="ssl"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isSSL ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isSSL ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
    </div>

    <div className="">
      <div className="relative z-1 flex items-center">
                <span className="mr-2">Authentication: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isAuth}
                    onChange={handleAuthenticationChange}
                    name="logintype"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isAuth ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isAuth ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
    </div>
  </div>

  <div className="moreEmailConfigContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Send To:</label>
      <input type="text" name="to" placeholder="Send To" value={send} onChange={handleSendChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">From As:</label>
      <input type="text" name="from" placeholder="From As" value={from} onChange={handleFromChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Subject:</label>
      <input type="text" name="subject" placeholder="Subject" value={subject} onChange={handleSubjectChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Text:</label>
      <input type="text" name="text" placeholder="Text" value={text} onChange={handleTextChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Attachment:</label>
      <input type="text" name="attachment" placeholder="Attachment" value={attachment} onChange={handleAttachmentChange} className="block p-2 border rounded" />
    </div>
  </div>
</div>
</>
)
}

export default EmailConfigs;