import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom'; 
import LoginPage from './LoginPage';
import { ChakraProvider } from '@chakra-ui/react';
import Dashboard from './Dashboard';
function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={ <ProtectedRoute><Dashboard/></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App

export function ProtectedRoute({ children }) {
  const user = localStorage.getItem("email");

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
}