import { useData } from "../../DataContext";
import { useEffect, useState } from "react";
import { useFormData } from "../../postContext";

function OSD(){

  const[data, setData] = useState([])
  
  const[timeStamp, setTimeStamp] = useState(false)
  const[cameraName, setCameraName] = useState(false)
  const[timeFormat, setTimeFormat] = useState("")
  const[xCordinateTF, setXCordinateTF] = useState("")
  const[yCordinateTF, setYCordinateTF] = useState("")
  const[tfWidth, setTFWidth] = useState("")
  const[tfHeight, setTFHeight] = useState("")
  const[name, setName] = useState("")
  const[xCordinate, setXCordinate] = useState("")
  const[yCordinate, setYCordinate] = useState("")
  const[width, setWidth] = useState("")
  const[height, setHeight] = useState("")
  const[font, setFont] = useState("")

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();


  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setTimeStamp(data.appSettings.osdCfg.show_0)
      setCameraName(data.appSettings.osdCfg.show_1)
      setTimeFormat(data.appSettings.osdCfg.cont_0)
      setXCordinateTF(data.appSettings.osdCfg.x_0)
      setYCordinateTF(data.appSettings.osdCfg.y_0)
      setTFWidth(data.appSettings.osdCfg.w_0)
      setTFHeight(data.appSettings.osdCfg.h_0)
      setName(data.appSettings.osdCfg.cont_1)
      setXCordinate(data.appSettings.osdCfg.x_1)
      setYCordinate(data.appSettings.osdCfg.y_1)
      setWidth(data.appSettings.osdCfg.w_1)
      setHeight(data.appSettings.osdCfg.h_1)
      setFont(data.appSettings.osdCfg.fontsize)
    }
  }, [fetchedData, data])

  const handleTimeStampChange = () => {
    const newValue = !timeStamp;
    setTimeStamp(newValue);
    updateFormData('show_0', newValue ? 1 : 0);
  }

  const handleTimeFormatChange = (e) => {
    setTimeFormat(e.target.value)
  }
  
  const handleXCordinateTFChange = (e) => {
    setXCordinateTF(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('x_0', newValue);
  }
  
  const handleYCordinateTFChange = (e) => {
    setYCordinateTF(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('y_0', newValue);
  }
  
  const handleTFWidth = (e) => {
    setTFWidth(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('w_0', newValue);
  }
  
  const handleTFHeight = (e) => {
    setTFHeight(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('h_0', newValue);
  }
  
  const handleNameChange = (e) => {
    setName(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('cont_1', newValue);
  }

  const handleXCordinateChange = (e) => {
    setXCordinate(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('x_1', newValue);
  }

  const handleYCordinateChange = (e) => {
    setYCordinate(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('y_1', newValue);
  }

  const handleWidthChange = (e) => {
    setWidth(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('w_1', newValue);
  }

  const handleHeightChange = (e) => {
    setHeight(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('h_1', newValue);
  }
  
  const handleFontSizeChange = (e) => {
    setFont(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('fontsize', newValue);
  }
  
  const handleCameraChange = () => {
    const newValue = !cameraName;
    setCameraName(newValue);
    updateFormData('show_1', newValue ? 1 : 0);
  }


    return (
      <>
        <div className="osdConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/8">OSD Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="osdConfigurataions p-10">
    <label htmlFor="fontLabel">Font Size:</label>
    <select id="fontLabel" name="fontSize" value={font} onChange={handleFontSizeChange} className="p-2 border rounded">
      <option value="1">Small</option>
      <option value="2">Normal</option>
      <option value="3">Large</option>
    </select>
  </div>

  <div className="osdConfigContent flex flex-wrap items-center gap-4 p-4">
    <div className="">
      <div className="relative z-1 flex items-center">
                <span className="mr-2">Timestamp: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={timeStamp}
                    onChange={handleTimeStampChange}
                    name="show_0"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      timeStamp ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        timeStamp ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
    </div>

    <div className="">
      <label className="mb-2">Time Format:</label>
      <input type="text" placeholder="Time Format" value={timeFormat} onChange={handleTimeFormatChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">x:</label>
      <input type="number" placeholder="x-coordinate" value={xCordinateTF} onChange={handleXCordinateTFChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">y:</label>
      <input type="number" placeholder="y-coordinate" value={yCordinateTF} onChange={handleYCordinateTFChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Width:</label>
      <input type="number" name="w_0" placeholder="Width" value={tfWidth} onChange={handleTFWidth} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Height:</label>
      <input type="number" placeholder="Height" value={tfHeight} onChange={handleTFHeight} className="block p-2 border rounded" />
    </div>
  </div>

  <div className="osdConfigContent items-center flex flex-wrap gap-4 p-4">
    <div className="">
      <div className="relative z-1 flex items-center">
                <span className="mr-2">Camera Name: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={cameraName}
                    onChange={handleCameraChange}
                    name="show_1"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      cameraName ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        cameraName ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
    </div>

    <div className="">
      <label className="mb-2">Name:</label>
      <input type="text" placeholder="Name" name="cont_1" value={name} onChange={handleNameChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">x:</label>
      <input type="number" placeholder="x-coordinate" value={xCordinate} onChange={handleXCordinateChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">y:</label>
      <input type="number" placeholder="y-coordinate" value={yCordinate} onChange={handleYCordinateChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Width:</label>
      <input type="number" placeholder="Width" value={width} onChange={handleWidthChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Height:</label>
      <input type="number" placeholder="Height" value={height} onChange={handleHeightChange} className="block p-2 border rounded" />
    </div>
  </div>
</div>
      </>
    );
}

export default OSD;