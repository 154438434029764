import React, { useState } from "react";
import {
  Flex,
  VStack,
  Box,
  Image,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// Import the signup function from your authentication API module
import { signup } from "../../pages/api/auth"; // Adjust the path as needed for your project structure

const SignupComponent = ({ onLoginClick, onOtpClick }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const showToast = (msg, status1) => {
    toast({
      description: msg,
      status: status1,
      duration: 2000,
      position: "bottom-left",
      isClosable: false,
    });
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters, with at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9), and one special character (@/#$%^&+=)."
      );
      return;
    }

    if (rememberMe) {
      localStorage.setItem("rememberMe", true);
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
    }

    try {
      setErrorMessage("");
      setIsLoading(true);

      const signupResult = await signup(email, password);
      localStorage.setItem("email", email);
      // localStorage.setItem('password', password);

      if (signupResult.success) {
        setIsLoading(false);
        onOtpClick();
        showToast("OTP sent Successfully", "success");
      } else {
        setIsLoading(false);
        setErrorMessage(signupResult.message);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("An error occurred during signup. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg="gray.50">
      <VStack spacing={4} w="full" maxW="md">
        <Box textAlign="center" mb={4}>
          <Heading size="lg">{`Join With Us...!`}</Heading>
        </Box>
        <form onSubmit={handleSignUp}>
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
          <FormControl id="password" mt={4}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Checkbox
            mt={2}
            isChecked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          >
            Remember me
          </Checkbox>
          {errorMessage && (
            <Text color="red.500" fontSize="md" mt={2}>
              {errorMessage}
            </Text>
          )}
          <Stack spacing={4} mt={4}>
            <Button
              type="submit"
              bg={"green.400"}
              color={"white"}
              _hover={{ bg: "green.500" }}
              isLoading={isLoading}
              w="100%"
            >
              {isLoading ? "Signing up..." : "Sign up"}
            </Button>
            <Text
              color={"blue.400"}
              textAlign="center"
              onClick={onLoginClick}
              cursor="pointer"
            >
              Already have an Account? Sign in
            </Text>
          </Stack>
        </form>
      </VStack>
    </Flex>
  );
};

export default SignupComponent;
