import React, { useState, useEffect } from 'react';
import { Text, Button, HStack, PinInput, PinInputField, FormControl, FormLabel, Alert, AlertIcon } from '@chakra-ui/react';
// Import the `verify` function from your authentication API module
import { login, reverify, verify } from '../../pages/api/auth'; // Adjust the path as needed for your project structure


const OtpComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOTP] = useState(['', '', '', '', '', '', '', '', '', '', '', '']);
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false); // State variable to track OTP submission
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false); // Track if the OTP is verified successfully

  const handleChange = (e, index) => {
    if (!isOtpSubmitted) {
      const value = e.target.value.trim(); // Remove leading/trailing spaces
      const updatedOTP = [...otp];
      updatedOTP[index] = value;
      setOTP(updatedOTP);
    } else {
      setOTP(['', '', '', '', '', '', '', '', '', '', '', '']);
      setIsOtpSubmitted(false);
    }
  };

  const handleSubmitOTP = async () => {
    const emails = localStorage.getItem('email');
    const otpValue = otp.join(''); // Join OTP array without a delimiter

    try {
      setErrorMessage('');
      setIsLoading(true); // Show the loader

      // Call the verify function and pass the email and OTP value
      const verifyResult = await verify(emails, otpValue);

      if (verifyResult.message === 'Email verification successfully') {
        setIsVerified(true); // Mark as verified
        setTimeout(() => {
          // Optionally, navigate to a different page or refresh the page
          window.location.reload();
        }, 2000);
      } else {
        setIsLoading(false);
        setErrorMessage(verifyResult.error);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('An error occurred during verification. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Text fontSize="2xl" mb={4}>Please Verify Your Account</Text>
      <FormControl id="otp">
        <FormLabel>Enter OTP</FormLabel>
        <HStack spacing={1}>
          <PinInput size="sm" variant="filled" placeholder='*'>
            {otp.map((digit, index) => (
              <PinInputField
                key={index}
                value={digit}  // Set the value of the field to the corresponding digit in the OTP array
                onChange={(e) => handleChange(e, index)}  // Handle changes in the OTP field
                isLast={index === otp.length - 1}
              />
            ))}
          </PinInput>
        </HStack>
      </FormControl>
      <Button
        bg={'green.400'}
        color={'white'}
        _hover={{
          bg: 'green.500',
        }}
        w="100%" mt={4} onClick={handleSubmitOTP}
        isLoading={isLoading}
      >
        {isLoading ? 'Verifying...' : 'Submit'}
      </Button>
      {isVerified && (
        <Alert status="success" mt={2}>
          <AlertIcon />
          We have sent you an OTP to your email for verification. Please check your inbox.
        </Alert>
      )}
      {errorMessage && (
        <Alert status="error" mt={2}>
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default OtpComponent;
