import { useData } from "../../DataContext";
import { useEffect, useState } from "react";
import { useFormData } from "../../postContext";

function NetworkConfig(){

  const[networkType, setNetworkType] = useState("")
  const[macAddress, setMacAddress] = useState("")
  const[ipAddress, setIpAddress] = useState("")
  const[netMask, setNetMask] = useState("")
  const[gateWay, setGateway] = useState("")
  const[primaryDNS, setPrimaryDNS] = useState("")
  const[secondaryDNS, setSecondaryDNS] = useState("")
  const[data, setData] = useState([])

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setNetworkType(data.appSettings.nwInfo.networktype)
      setMacAddress(data.appSettings.nwInfo.macaddress)
      setIpAddress(data.appSettings.nwInfo.ip)
      setNetMask(data.appSettings.nwInfo.netmask)
      setGateway(data.appSettings.nwInfo.gateway)
      setPrimaryDNS(data.appSettings.nwInfo.fdnsip)
      setSecondaryDNS(data.appSettings.nwInfo.sdnsip)
    }
  },[data, fetchedData])


  const handleNetworkType = (e) => {
    setNetworkType(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('networktype', newValue);
  }
  
  const handleMacAdressChange = (e) => {
    setMacAddress(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('macaddress', newValue);
  }
  
  const handleIpAddressChange = (e) => {
    setIpAddress(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('ip', newValue);
  }
  
  const handleNetMaskChange = (e) => {
    setNetMask(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('netmask', newValue);
  }
  
  const handleGatewayChange = (e) => {
    setGateway(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('gateway', newValue);
  }
  
  const handlePrimaryDNSChange = (e) => {
    setPrimaryDNS(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('fdnsip', newValue);
  }
  
  const handleSecondaryDNSChange = (e) => {
    setPrimaryDNS(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('sdnsip', newValue);
  }

    return (
      <>
        <div className="networkInfoConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/5">Network Info Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="networkInfoContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Type:</label>
      <input
        type="text"
        name="networktype"
        placeholder="Type"
        value={networkType}
        onChange={handleNetworkType}
        className="block p-2 border rounded"
      />
    </div>

    <div className="">
      <label className="mb-2">MAC:</label>
      <input
        type="text"
        name="macaddress"
        placeholder="MAC Address"
        value={macAddress}
        onChange={handleMacAdressChange}
        className="block p-2 border rounded"
      />
    </div>

    <div className="">
      <label className="mb-2">IP:</label>
      <input
        type="text"
        name="ip"
        placeholder="IP Address"
        value={ipAddress}
        onChange={handleIpAddressChange}
        className="block p-2 border rounded"
      />
    </div>

    <div className="">
      <label className="mb-2">NM:</label>
      <input
        type="text"
        name="netmask"
        placeholder="Netmask"
        value={netMask}
        onChange={handleNetMaskChange}
        className="block p-2 border rounded"
      />
    </div>
  </div>

  <div className="networkInfoContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">GW:</label>
      <input
        type="text"
        name="gateway"
        placeholder="Gateway"
        value={gateWay}
        onChange={handleGatewayChange}
        className="block p-2 border rounded"
      />
    </div>

    <div className="">
      <label className="mb-2">Primary DNS:</label>
      <input
        type="text"
        name="fdnsip"
        placeholder="Primary DNS"
        value={primaryDNS}
        onChange={handlePrimaryDNSChange}
        className="block p-2 border rounded"
      />
    </div>

    <div className="">
      <label className="mb-2">Secondary DNS:</label>
      <input
        type="text"
        name="sdnsip"
        placeholder="Secondary DNS"
        value={secondaryDNS}
        onChange={handleSecondaryDNSChange}
        className="block p-2 border rounded"
      />
    </div>
  </div>
</div>

      </>
    );
}

export default NetworkConfig;