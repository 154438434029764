import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from '@chakra-ui/react';
// Import the forgotPassword function from your authentication API module
import { forgotPassword} from '../../pages/api/auth'; // Adjust the path as needed for your project structure


const ForgotPassword = ({ onLoginClick2 }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toast = useToast();

  const showToast = (msg, status1) => {
    toast({
      description: msg,
      status: status1,
      duration: 2000,
      position: 'bottom-left',
      isClosable: false,
    });
  };

  const handleForgotPassword = async () => {
    try {
      setErrorMessage('');
      setIsLoading(true); // Show the loader

      // Call the forgotPassword function and pass the email
      const forgotPasswordResult = await forgotPassword(email);

      if (forgotPasswordResult.success) {
        setIsLoading(false);
        showToast('Password reset email sent successfully', 'success');
      } else {
        setIsLoading(false);
        setErrorMessage('Invalid email');
        showToast('Invalid Email', 'error');
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('An error occurred');
      showToast('An error occurred', 'error');
      console.error('Error:', error);
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
      p={4}
    >
      <Box
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="lg"
        maxW="md"
        w="full"
      >
        <Text fontSize="2xl" mb={4} textAlign="center">
          Forgot Your Password
        </Text>
        {errorMessage && (
          <Text color="red.500" fontSize="md" mb={4}>
            {errorMessage}
          </Text>
        )}
        <FormControl id="forgot-password-email" mb={4}>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <Button
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
          isLoading={isLoading}
          onClick={handleForgotPassword}
          w="100%"
        >
          {isLoading ? 'Sending email...' : 'Send reset email'}
        </Button>
        <Text
          color={'blue.400'}
          textAlign="center"
          mt={4}
          onClick={onLoginClick2}
          cursor="pointer"
        >
          Remember your password? Log in
        </Text>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
