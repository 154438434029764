import { useEffect, useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";
import classNames from "classnames"

function StreamConfig(){

  const[data, setData] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [enable, setIsEnable] = useState(false)
  const [isAudio, setAudio] = useState(false)
  const [isTelnet, setTelnet] = useState(false)
  const [isHD, setHD] = useState(false)
  const [rtmp, setRtmp] = useState("")
  const [mqtt, setMqtt] = useState("")
  const [telnetUrl, setTelnetUrl] = useState("")
  const [fwUpdate, setFwUpdate] = useState("")

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setRtmp(data.appSettings.streamCfg.publishUrl)
      setMqtt(data.appSettings.streamCfg.mqttUrl)
      setTelnetUrl(data.appSettings.streamCfg.telnetUrl)
      setFwUpdate(data.appSettings.streamCfg.fwUpdtTo)
      setIsChecked(data.appSettings.streamCfg.enabled)
      setAudio(data.appSettings.streamCfg.enableAudio)
      setHD(data.appSettings.streamCfg.isHd)
      setTelnet(data.appSettings.streamCfg.enableTelnet)
    }
  },[setIsChecked, fetchedData, data])

  // 
  const handleEnableToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    updateFormData('enabled', newValue ? 1 : 0);
  }
  
  const handleAudioToggle = () => {
    const newValue = !isAudio;
    setAudio(newValue);
    updateFormData('enableAudio', newValue ? 1 : 0);
  }
  
  const handleTelnetToogle = () => {
    const newValue = !isTelnet;
    setTelnet(newValue);
    updateFormData('enableTelnet', newValue ? 1 : 0);
  }
  
  const handleHDToogle = () => {
    const newValue = !isHD;
    setHD(newValue);
    updateFormData('isHd', newValue ? 1 : 0);
  }
  
  const handleRtmpChange = (e) => {
    setRtmp(e.target.value)

     //set configurations
     const newValue = e.target.value;
     updateFormData('publishUrl', newValue);
  }
  
  const handleMqttChange = (e) => {
    setMqtt(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('mqttUrl', newValue);
  }
  
  const handleTelnetChange = (e) => {
    setTelnetUrl(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('telnetUrl', newValue);
  }
  
  const handleFwUpdateChange = (e) => {
    setFwUpdate(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('fwUpdtTo', newValue);
  }

    return (
      <>
        <div className="streamConfigs w-full p-10">
          <div className="mainHeadingDiv flex items-center">
            <h1 className="font-medium text-lg w-1/6">Stream Configurations</h1>
            <div className="headingDiv w-full border-t border-lightGray"></div>
          </div>
          <div className="streamConfigContent flex items-center flex-wrap gap-2 p-4">
              <div className="relative z-1 flex items-center">
                <span className="mr-2">Enable: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isChecked}
                    onChange={handleEnableToggle}
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isChecked ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isChecked ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
              <div className="relative z-1 flex items-center">
                <span className="mr-2">Audio: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isAudio}
                    onChange={handleAudioToggle}
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isAudio ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isAudio ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            <div className=" mb-4">
              <label className="block mb-2">RTMP URL:</label>
              <input
                type="text"
                name="publishUrl"
                placeholder="RTMP URL"
                onChange={handleRtmpChange}
                value={rtmp}
                className="p-2 border rounded"
              />
              
            </div>
            <div className=" mb-4">
              <label className="block mb-2">MQTT URL:</label>
              <input
                type="text"
                name="mqttUrl"
                placeholder="MQTT URL"
                onChange={handleMqttChange}
                value={mqtt}
                className=" p-2 border rounded"
              />
            </div>
            <div className="">
               <div className="relative z-1 flex items-center">
                <span className="mr-2">Telnet: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isTelnet}
                    onChange={handleTelnetToogle}
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isTelnet ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isTelnet ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">URL:</label>
              <input
                type="text"
                name="telnetUrl"
                placeholder="URL"
                onChange={handleTelnetChange}
                value={telnetUrl}
                className="p-2 border rounded"
              />
            </div>
            <div className="">
                <div className="relative z-1 flex items-center">
                <span className="mr-2">HD: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isHD}
                    onChange={handleHDToogle}
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isHD ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isHD ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>
            <div className=" mb-4">
              <label className="block mb-2">FW Update:</label>
              <input
                type="text"
                name="fwUpdtTo"
                placeholder="FW update"
                onChange={handleFwUpdateChange}
                value={fwUpdate}
                className="p-2 border rounded"
              />
            </div>
          </div>
        </div>
      </>
    );
}

export default StreamConfig;