import { useEffect, useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";


function PTZConfigs(){

  const[data, setData] = useState([])
  const[left, setLeft] = useState(null)
  const[right, setRight] = useState("")
  const[up, setUp] = useState("")
  const[down, setDown] = useState("")
  const[far, setFar] = useState("")
  const[near, setNear] = useState("")
  const[panPos, setPanPos] = useState("")
  const[tiltPos, setTiltPos] = useState("")
  const[zoom, setZoomPos] = useState("")

  const{fetchedData} = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setLeft(data.appSettings.PtzCfg.leftPos)
      setRight(data.appSettings.PtzCfg.rightPos)
      setUp(data.appSettings.PtzCfg.upPos)
      setDown(data.appSettings.PtzCfg.downPos)
      setFar(data.appSettings.PtzCfg.farPos)
      setNear(data.appSettings.PtzCfg.nearPos)
      setPanPos(data.appSettings.PtzCfg.currPanPos)
      setTiltPos(data.appSettings.PtzCfg.currTiltPos)
      setZoomPos(data.appSettings.PtzCfg.currZoomPos)
    }
  }, [fetchedData, data])

  const handleLeftFieldChange = (e) => {
    setLeft(e.target.value)

    //set configurations
    const newValue =  parseInt(e.target.value, 10)
    updateFormData('leftPos', newValue);
    console.log(newValue, " :: Left Position")
  }
  
  const handleRightFieldChange = (e) => {
    setRight(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('rightPos', newValue);
  }
  
  const handleUpFieldChange = (e) => {
    setUp(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('upPos', newValue);
  }
  
  const handleDownFieldChange = (e) => {
    setDown(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('downPos', newValue);
  }
  
  const handleFarFieldChange = (e) => {
    setFar(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('farPos', newValue);
  }
  
  const handleNearFieldChange = (e) => {
    setNear(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('nearPos', newValue);
  }
  
  const handlePanPositionChange = (e) => {
    setPanPos(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('currPanPos', newValue);
  }
  
  const handleTiltPosChange = (e) => {
    setTiltPos(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('currTiltPos', newValue);
  }
  
  const handleZoomPosChange = (e) => {
    setZoomPos(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('currZoomPos', newValue);
  }

    return (
      <>
       <div className="ptzConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/8">PTZ Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="ptzConfigContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Left:</label>
      <input type="number" name="leftPos" placeholder="Left" value={left} onChange={handleLeftFieldChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Right:</label>
      <input type="number" name="rightPos" placeholder="Right" value={right} onChange={handleRightFieldChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Up:</label>
      <input type="number" name="upPos" placeholder="Up" value={up} onChange={handleUpFieldChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Down:</label>
      <input type="number" name="downPos" placeholder="Down" value={down} onChange={handleDownFieldChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Far:</label>
      <input type="number" name="farPos" placeholder="Far" value={far} onChange={handleFarFieldChange} className="block p-2 border rounded" />
    </div>
  </div>

  <div className="ptzConfigContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Near:</label>
      <input type="number" name="nearPos" placeholder="Near" value={near} onChange={handleNearFieldChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Current Pan Position:</label>
      <input type="number" name="currPanPos" placeholder="Pan Position" value={panPos} onChange={handlePanPositionChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Current Tilt Position:</label>
      <input type="number" name="currTiltPos" placeholder="Tilt Position" value={tiltPos} onChange={handleTiltPosChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Current Zoom Position:</label>
      <input type="number" name="currZoomPos" placeholder="Zoom Position" value={zoom} onChange={handleZoomPosChange} className="block p-2 border rounded" />
    </div>
  </div>
</div>
      </>
    );
}

export default PTZConfigs;