import React, { useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";
import axios from "axios";
import { Toaster, toast } from 'sonner';
import { useNavigate } from "react-router-dom";
import { logout } from "../../pages/api/auth";

function CameraConfigs(){

  const { updateFetchedData } = useData()
  const { formData } = useFormData();
  const { fetchedData } = useData()
  const [deviceId, setDeviceId] = useState('');
  const[dropdownValue, setDropDownValue] = useState("")
  const navigate = useNavigate();

  const handleInput = (e) => {
    setDeviceId(e.target.value)
  }

  const dropDownChange = (e) => {
    setDropDownValue(e.target.value)

    if(e.target.value == "Reboot"){
      console.log("Reboot")
      axios.post("https://mqtt-api-ocxhv.ondigitalocean.app/getReboot", {
        deviceId: deviceId,
      }).then( response => {
        if(response.status === 200)
        toast.success("Device Reboot success");
      })
      .catch((error) => {
        toast.error("Error fetching data");
      });
      };
    }
  
  const handleGetConfig = () => {
    if (!deviceId) {
      toast.error("Please enter a device ID");
    }
    axios
    .post("https://mqtt-api-ocxhv.ondigitalocean.app/getsetting", {
      deviceId: deviceId,
    })
    .then((response) => {
      updateFetchedData(response.data);
      toast.success("Data fetched successfully");
    })
    .catch((error) => {
      toast.error("Error fetching data");
    });
    };
  
    const handleSetConfig = () => {
    if (!deviceId) {
      toast.error("Please enter a device ID");
    }
      axios
        .post("https://mqtt-api-ocxhv.ondigitalocean.app/setCameraSettings", {
            "appSettings": {
              "uuid": deviceId,
              "grUuid": !formData.grUuid ? fetchedData.appSettings.grUuid : formData.grUuid,
              "streamCfg": {
                "enabled": formData.enabled !== undefined ? (formData.enabled ? 1 : 0) : fetchedData.appSettings.streamCfg.enabled,
                "enableAudio": formData.enableAudio !== undefined ? (formData.enableAudio ? 1 : 0) : fetchedData.appSettings.streamCfg.enableAudio,
                "enableTelnet": formData.enableTelnet !== undefined ? (formData.enableTelnet ? 1 : 0) : fetchedData.appSettings.streamCfg.enableTelnet,
                "isHd": formData.isHd !== undefined ? (formData.isHd ? 1 : 0) : fetchedData.appSettings.streamCfg.isHd,
                "publishUrl": !formData.publishUrl ? fetchedData.appSettings.streamCfg.publishUrl : formData.publishUrl,
                "mqttUrl": !formData.mqttUrl ? fetchedData.appSettings.streamCfg.mqttUrl : formData.mqttUrl,
                "telnetUrl": !formData.telnetUrl ? fetchedData.appSettings.streamCfg.telnetUrl : formData.telnetUrl,
                "fwUpdtTo": !formData.fwUpdtTo ? fetchedData.appSettings.streamCfg.fwUpdtTo : formData.fwUpdtTo
              },
              "timeCfg": {
                "dstmode": formData.dstmode !== undefined ? (formData.dstmode ? 1 : 0) : fetchedData.appSettings.timeCfg.dstmode,
                "autoupdate": formData.autoupdate !== undefined ? (formData.autoupdate ? 1 : 0) : fetchedData.appSettings.timeCfg.autoupdate,
                "autoupdatetzonvif": formData.autoupdatetzonvif !== undefined ? (formData.autoupdatetzonvif ? 1 : 0) : fetchedData.appSettings.timeCfg.autoupdatetzonvif,
                "ntpinterval": !formData.ntpinterval ? fetchedData.appSettings.timeCfg.ntpinterval : formData.ntpinterval,
                "ntpenable":  formData.ntpenable !== undefined ? (formData.ntpenable ? 1 : 0) : fetchedData.appSettings.timeCfg.ntpenable,
                "time": "1703755036",
                "timeZone": "Asia/Calcutta",
                "tz": "STD: +5:30",
                "ntpserver": !formData.ntpserver ? fetchedData.appSettings.timeCfg.ntpserver : formData.ntpserver
              },
              "emailCfg": {
                "emailserver": !formData.emailserver ? fetchedData.appSettings.emailCfg.emailserver : formData.emailserver,
                "emailusername": !formData.emailusername ? fetchedData.appSettings.emailCfg.emailusername : formData.emailusername,
                "emailpassword": !formData.emailpassword ? fetchedData.appSettings.emailCfg.emailpassword : formData.emailpassword,
                "from": !formData.from ? fetchedData.appSettings.emailCfg.from : formData.from,
                "to": !formData.to ? fetchedData.appSettings.emailCfg.to : formData.to,
                "subject": !formData.subject ? fetchedData.appSettings.emailCfg.subject : formData.subject,
                "text": !formData.text ? fetchedData.appSettings.emailCfg.text : formData.text,
                "attatchment": !formData.attatchment ? fetchedData.appSettings.emailCfg.attatchment : formData.attatchment,
                "emailport": !formData.emailport ? fetchedData.appSettings.emailCfg.emailport : formData.emailport,
                "ssl": formData.ssl !== undefined ? (formData.flip ? 1 : 0) : fetchedData.appSettings.emailCfg.ssl,
                "logintype": formData.logintype !== undefined ? (formData.logintype ? 1 : 0) : fetchedData.appSettings.emailCfg.logintype,
              },
              "videoCh011": {
                "bps": 512,
                "fps": 20,
                "gop": 60,
                "brmode": 2,
                "piclevel": 1,
                "fixqplevel": 1,
                "width": 1280,
                "height": 720,
                "bmainstream": 1,
                "bfield": 0
              },
              "videoCh012": {
                "bps": 256,
                "fps": 15,
                "gop": 60,
                "brmode": 2,
                "piclevel": 1,
                "fixqplevel": 1,
                "width": 640,
                "height": 360,
                "bmainstream": 1,
                "bfield": 0
              },
              "videoCh013": {
                "bps": 64,
                "fps": 5,
                "gop": 15,
                "brmode": 2,
                "piclevel": 4,
                "fixqplevel": 4,
                "width": 320,
                "height": 180,
                "bmainstream": 1,
                "bfield": 0
              },
              "displayCfg": {
                "hue": !formData.hue ? fetchedData.appSettings.displayCfg.hue : formData.hue,
                "brightness": !formData.brightness ? fetchedData.appSettings.displayCfg.brightness : formData.brightness,
                "saturation": !formData.saturation ? fetchedData.appSettings.displayCfg.saturation : formData.saturation,
                "contrast": !formData.contrast ? fetchedData.appSettings.displayCfg.contrast : formData.contrast,
                "ircutmode": !formData.ircutmode ? fetchedData.appSettings.displayCfg.ircutmode : formData.ircutmode,
              },
              "osdCfg": {
                "rgncnt": !formData.rgncnt ? fetchedData.appSettings.osdCfg.rgncnt : formData.rgncnt,
                "fontsize": !formData.fontsize ? fetchedData.appSettings.osdCfg.fontsize : formData.fontsize,
                "x_0": !formData.x_0 ? fetchedData.appSettings.osdCfg.x_0 : formData.x_0,
                "y_0": !formData.y_0 ? fetchedData.appSettings.osdCfg.y_0 : formData.y_0, 
                "w_0": !formData.w_0 ? fetchedData.appSettings.osdCfg.w_0 : formData.w_0,
                "h_0": !formData.h_0 ? fetchedData.appSettings.osdCfg.h_0 : formData.h_0,
                "cont_0": "YYYY-MM-DD hh:mm:ss",
                "show_0": formData.show_0 !== undefined ? (formData.show_0 ? 1 : 0) : fetchedData.appSettings.show_0,
                "x_1": !formData.x_1 ? fetchedData.appSettings.osdCfg.x_1 : formData.x_1,
                "y_1": !formData.y_1 ? fetchedData.appSettings.osdCfg.y_1 : formData.y_1,
                "w_1": !formData.w_1 ? fetchedData.appSettings.osdCfg.w_1 : formData.w_1,
                "h_1": !formData.h_1 ? fetchedData.appSettings.osdCfg.h_1 : formData.h_1,
                "cont_1": !formData.cont_1 ? fetchedData.appSettings.osdCfg.cont_1 : formData.cont_1,
                "show_1": formData.show_1 !== undefined ? (formData.show_1 ? 1 : 0) : fetchedData.appSettings.show_1,
              },
              "recordCh011": {
                "startTimerRec": !formData.startTimerRec ? fetchedData.appSettings.recordCh011.startTimerRec : fetchedData.appSettings.recordCh011.startTimerRec,
                "startManualRec": !formData.startManualRec ? fetchedData.appSettings.recordCh011.startManualRec : fetchedData.appSettings.recordCh011.startManualRec,
                "singlefiletime": !formData.singlefiletime ? fetchedData.appSettings.recordCh011.singlefiletime : fetchedData.appSettings.recordCh011.singlefiletime,
                "enable": !formData.enable ? fetchedData.appSettings.recordCh011.enable : fetchedData.appSettings.recordCh011.enable,
                "filepath": !formData.enable ? fetchedData.appSettings.recordCh011.filepath : fetchedData.appSettings.recordCh011.filepath
              },
              "recordCh012": {
                "startTimerRec": !formData.startTimerRec ? fetchedData.appSettings.recordCh012.startTimerRec : fetchedData.appSettings.recordCh011.startTimerRec,
                "startManualRec": !formData.startManualRec ? fetchedData.appSettings.recordCh012.startManualRec : fetchedData.appSettings.recordCh011.startManualRec,
                "singlefiletime": !formData.singlefiletime ? fetchedData.appSettings.recordCh012.singlefiletime : fetchedData.appSettings.recordCh011.singlefiletime,
                "enable": !formData.enable ? fetchedData.appSettings.recordCh012.enable : fetchedData.appSettings.recordCh011.enable,
                "filepath": !formData.enable ? fetchedData.appSettings.recordCh012.filepath : fetchedData.appSettings.recordCh011.filepath
              },
              "recordSch": {
                "etm": !formData.etm ? fetchedData.appSettings.recordSch.etm : fetchedData.appSettings.recordSch.etm,
                "enWorkday":!formData.enWorkday ? fetchedData.appSettings.recordSch.enWorkday : fetchedData.appSettings.recordSch.enWorkday,
                "enWeekend": !formData.enWeekend ? fetchedData.appSettings.recordSch.enWeekend : fetchedData.appSettings.recordSch.enWeekend,
                "enSun": !formData.enSun ? fetchedData.appSettings.recordSch.enSun : fetchedData.appSettings.recordSch.enSun,
                "enMon": !formData.enMon ? fetchedData.appSettings.recordSch.enMon : fetchedData.appSettings.recordSch.enMon,
                "enTue": !formData.enTue ? fetchedData.appSettings.recordSch.enTue : fetchedData.appSettings.recordSch.enTue,
                "enWed": !formData.enWed ? fetchedData.appSettings.recordSch.enWed : fetchedData.appSettings.recordSch.enWed,
                "enThu": !formData.enThu ? fetchedData.appSettings.recordSch.enThu : fetchedData.appSettings.recordSch.enThu,
                "enFri": !formData.enFri ? fetchedData.appSettings.recordSch.enFri : fetchedData.appSettings.recordSch.enFri,
                "enSat": !formData.enSat ? fetchedData.appSettings.recordSch.enSat : fetchedData.appSettings.recordSch.enSat,
                "workday": [0, -1, 0, -1, 0, -1, null, null],
                "weekend": [0, -1, 0, -1, 0, -1, null, null],
                "sun": [0, -1, 0, -1, 0, -1, null, null],
                "mon": [0, -1, 0, -1, 0, -1, null,  null],
                "tue": [0, -1, 0, -1, 0, -1, null, null],
                "wed": [0, -1, 0, -1, 0, -1, null, null],
                "thu": [0, -1, 0, -1, 0, -1, null, null],
                "fri": [0, -1, 0, -1, 0, -1, null, null],
                "sat": [0, -1, 0, -1, 0, -1, null, null],
              },
              "imageCfg": {
                "devno": !formData.devno ? fetchedData.appSettings.imageCfg.devno : formData.devno,
                "chn": !formData.chn ? fetchedData.appSettings.imageCfg.chn : formData.chn,
                "flip":  formData.flip !== undefined ? (formData.flip ? 1 : 0) : fetchedData.appSettings.flip,
                "mirror": formData.mirror !== undefined ? (formData.mirror ? 1 : 0) : fetchedData.appSettings.mirror,
                "wdr": !formData.wdr ? fetchedData.appSettings.imageCfg.wdr : formData.wdr,
              },
              "mdCfg": {
                "md_email_switch": !formData.md_email_switch ? fetchedData.appSettings.mdCfg.md_email_switch : fetchedData.appSettings.mdCfg.md_email_switch,
                "md_snap_switch": !formData.md_snap_switch ? fetchedData.appSettings.mdCfg.md_snap_switch : fetchedData.appSettings.mdCfg.md_snap_switch,
                "md_emailsnap_switch": !formData.md_emailsnap_switch ? fetchedData.appSettings.mdCfg.md_emailsnap_switch : fetchedData.appSettings.mdCfg.md_emailsnap_switch,
                "md_ftpsnap_switch": !formData.md_ftpsnap_switch ? fetchedData.appSettings.mdCfg.md_ftpsnap_switch : fetchedData.appSettings.mdCfg.md_ftpsnap_switch,
                "md_record_switch":  !formData.md_record_switch ? fetchedData.appSettings.mdCfg.md_record_switch : fetchedData.appSettings.mdCfg.md_record_switch,
                "md_ftprec_switch":  !formData.md_ftprec_switch ? fetchedData.appSettings.mdCfg.md_ftprec_switch : fetchedData.appSettings.mdCfg.md_ftprec_switch,
                "md_ioalmdo_switch": !formData.md_ioalmdo_switch ? fetchedData.appSettings.mdCfg.md_ioalmdo_switch : fetchedData.appSettings.mdCfg.md_ioalmdo_switch,
                "etm": !formData.etm ? fetchedData.appSettings.mdCfg.etm : fetchedData.appSettings.mdCfg.etm,
                "workday": !formData.workday ? fetchedData.appSettings.mdCfg.workday : fetchedData.appSettings.mdCfg.workday,
                "weekend": !formData.weekend ? fetchedData.appSettings.mdCfg.weekend : fetchedData.appSettings.mdCfg.weekend,
                "md_interval": !formData.md_interval ? fetchedData.appSettings.mdCfg.md_interval : fetchedData.appSettings.mdCfg.md_interval,
                "MdbEnable": !formData.MdbEnable ? fetchedData.appSettings.mdCfg.MdbEnable : fetchedData.appSettings.mdCfg.MdbEnable,
                "MdSensitiValue": !formData.MdSensitiValue ? fetchedData.appSettings.mdCfg.MdSensitiValue : fetchedData.appSettings.mdCfg.MdSensitiValue,
                "MDThresholdValue": !formData.MDThresholdValue ? fetchedData.appSettings.mdCfg.MDThresholdValue : fetchedData.appSettings.mdCfg.MDThresholdValue,
                "MdInterval": !formData.MdInterval ? fetchedData.appSettings.mdCfg.MdInterval : fetchedData.appSettings.mdCfg.MdInterval,
                "MdRegion": [
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  255,
                  0,
                  0
                ],
                "md_alarm": !formData.md_alarm ? fetchedData.appSettings.mdCfg.md_alarm : fetchedData.appSettings.mdCfg.md_alarm,
                "defend_alarm": !formData.defend_alarm ? fetchedData.appSettings.mdCfg.defend_alarm : fetchedData.appSettings.mdCfg.defend_alarm,
                "tc_alarm": !formData.tc_alarm ? fetchedData.appSettings.mdCfg.tc_alarm : fetchedData.appSettings.mdCfg.tc_alarm
              },
              "devInfo": {
                "hwVer": !formData.hwVer ? fetchedData.appSettings.devInfo.hwVer : formData.hwVer,
                "swVer": !formData.swVer ? fetchedData.appSettings.devInfo.swVer : formData.swVer,
                "provisioningVer": !formData.x_0 ? fetchedData.appSettings.devInfo.provisioningVer : formData.provisioningVer,
                "publisherVer": !formData.publisherVer ? fetchedData.appSettings.devInfo.publisherVer : formData.publisherVer,
                "serialNo": !formData.serialNo ? fetchedData.appSettings.devInfo.serialNo : formData.serialNo,
                "sdStatus": "",
                "sdSize": !formData.sdSize ? fetchedData.appSettings.devInfo.sdSize : formData.sdSize,
                "sdleftSize": !formData.sdleftSize ? fetchedData.appSettings.devInfo.sdleftSize : formData.sdleftSize,
              },
              "nwInfo": {
                "networktype": !formData.networktype ? fetchedData.appSettings.nwInfo.networktype : formData.networktype,
                "macaddress": !formData.macaddress ? fetchedData.appSettings.nwInfo.macaddress : formData.macaddress,
                "ip": !formData.ip ? fetchedData.appSettings.nwInfo.ip : formData.ip,
                "netmask": !formData.netmask ? fetchedData.appSettings.nwInfo.netmask : formData.netmask,
                "gateway": !formData.gateway ? fetchedData.appSettings.nwInfo.gateway : formData.gateway,
                "sdnsip": !formData.sdnsip ? fetchedData.appSettings.nwInfo.sdnsip : formData.sdnsip,
                "fdnsip": !formData.fdnsip ? fetchedData.appSettings.nwInfo.fdnsip : formData.fdnsip
              },
              "PtzCfg": {
                "leftPos":  formData.leftPos === undefined ? fetchedData.appSettings.PtzCfg.leftPos : formData.leftPos,
                "rightPos": formData.rightPos === undefined ? fetchedData.appSettings.PtzCfg.rightPos : formData.rightPos,
                "upPos": formData.upPos === undefined ? fetchedData.appSettings.PtzCfg.upPos : formData.upPos,
                "downPos": formData.downPos === undefined ? fetchedData.appSettings.PtzCfg.downPos : formData.downPos,
                "farPos": !formData.farPos ? fetchedData.appSettings.PtzCfg.farPos : formData.farPos,
                "nearPos": !formData.nearPos ? fetchedData.appSettings.PtzCfg.nearPos : formData.nearPos,
                "currPanPos": !formData.currPanPos ? fetchedData.appSettings.PtzCfg.currPanPos : formData.currPanPos,
                "currTiltPos": formData.currTiltPos === undefined ? fetchedData.appSettings.PtzCfg.currTiltPos : formData.currTiltPos,
                "currZoomPos": formData.currZoomPos === undefined ? fetchedData.appSettings.PtzCfg.currZoomPos : formData.currZoomPos
              },
              "rectime": 30
            }
          })
        .then((response) => {
          updateFetchedData(response.data);
          toast.success("Data updated successfully");
        })
        .catch((error) => {
          toast.error("Error fetching data");
        })
      }

      const handleLogout = async () => {
        // Perform logout logic here
        try {
          // Call the logout API
          const logoutResult = await logout();
      
          if (logoutResult.success) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, []);
          } else {
            console.error('Logout error:', logoutResult.message);
          }
        } catch (error) {
          // Handle unexpected errors, e.g., network issues
          console.error('Logout error:', error);
        }
      };

    return ( 
    <>
        <Toaster richColors />
        <div className="flex flex-col items-center bg-gray-100">
      <div className="w-full h-28 flex flex-wrap justify-around items-center bg-navigation z-50 fixed top-0 left-0 transition-all duration-600">
        <div className="relative">
          <h1 className="font-light text-white text-2xl">Ambicam Provisioning</h1>
        </div>
        <div className="flex flex-wrap gap-3 relative font-semibold">
          <input
            type="text"
            placeholder="enter device id"
            onChange={handleInput}
            className="w-full sm:w-60 p-2 border rounded-xl bg-lightGray focus:outline-none"
          />
          <button
            onClick={handleGetConfig}
            className="w-full sm:w-auto px-4 py-2 border border-transparent rounded-xl bg-white cursor-pointer hover:bg-button"
          >
            Get Configs
          </button>
          <button
            onClick={handleSetConfig}
            className="w-full sm:w-auto px-4 py-2 border border-transparent rounded-xl bg-white cursor-pointer hover:bg-button"
          >
            Set Configs
          </button>
        
            <select
              id="intervalLabel"
              name="cameraConfigs"
              className="p-3 border rounded-full"
              onChange={dropDownChange}
              defaultValue={"Connect"}
            >
              <option value="Reboot">Reboot</option>
              <option value="Connect">Connect</option>
              <option value="FactoryReset">Factory Reset</option>
            </select>
            <button
              onClick={handleLogout}
              className="w-full sm:w-auto px-4 py-2 border border-transparent rounded-xl bg-white cursor-pointer hover:bg-button"
            >
              Logout
            </button>
          </div>
      </div>
      </div>
      </>
    )
    }
export default CameraConfigs;