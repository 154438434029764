import { useEffect, useState } from "react"
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";


function VideoConfig(){


  const { formData, updateFormData } = useFormData();

  const { fetchedData } = useData()
  const[data, setData] = useState([])
  const[bitrate, setBitRate] = useState("")
  // const[bitrateType, setBitRateType] = useState("")
  const[quality, setQuality] = useState("")
  // const[resolution, setResolution] = useState("")
  const[frameRate, setFPS] = useState("")
  const[gop, setGOP] = useState("")
  const[bmainStream, setBmainStream] = useState("")
  const[bfield, setBField] = useState("")
  const[v2Bitrate, setV2BitRate] = useState("")
  const[v2FrameRate, setV2FPS] = useState("")
  const[v2Gop, setV2GOP] = useState("")
  const[v2BmainStream, setV2BmainStream] = useState("")
  const[v2Bfield, setV2BField] = useState("")
  const[v3Bitrate, setV3BitRate] = useState("")
  const[v3FrameRate, setV3FPS] = useState("")
  const[v3Gop, setV3GOP] = useState("")
  const[v3BmainStream, setV3BmainStream] = useState("")
  const[v3Bfield, setV3BField] = useState("")

  useEffect(()=>{
    setData(fetchedData)
    if(data && data.appSettings){
      setBitRate(data.appSettings.videoCh011.bps)
      setFPS(data.appSettings.videoCh011.fps)
      setGOP(data.appSettings.videoCh011.gop)
      // setBitRateType(data.appSettings.videoCh011.gop)
      setBmainStream(data.appSettings.videoCh011.bmainstream)
      setBField(data.appSettings.videoCh011.bfield)
      setV2BitRate(data.appSettings.videoCh012.bps)
      setV2FPS(data.appSettings.videoCh012.fps)
      setV2GOP(data.appSettings.videoCh012.gop)
      setV2BmainStream(data.appSettings.videoCh012.bmainstream)
      setV2BField(data.appSettings.videoCh012.bfield)
      setV3BitRate(data.appSettings.videoCh013.bps)
      setV3FPS(data.appSettings.videoCh013.fps)
      setV3GOP(data.appSettings.videoCh013.gop)
      setV3BmainStream(data.appSettings.videoCh013.bmainstream)
      setV3BField(data.appSettings.videoCh013.bfield)
    }
  }, [fetchedData, data])

    const handledQualityChange = (e) => {
        setQuality(e.target.value)
    }
    
    const handledBitRateChange = (e) => {
      setBitRate(e.target.value)

      //set configurations
      const newValue = e.target.value;
      updateFormData('bps', newValue);
    }
    
    const handleFPSChange = (e) => {
        setFPS(e.target.value)

        //set configurations
      const newValue = e.target.value;
      updateFormData('fps', newValue);
    }
    
    const handleGOPChange = (e) => {
        setGOP(e.target.value)

      //set configurations
      const newValue = e.target.value;
      updateFormData('gop', newValue);
    }
    
    const handleBmainStreamChange = (e) => {
        setBmainStream(e.target.value)

        //set configurations
      const newValue = e.target.value;
      updateFormData('bmainstream', newValue);
    }
   
    const handleBFieldChange = (e) => {
        setBField(e.target.value)

       //set configurations
       const newValue = e.target.value;
       updateFormData('bfield', newValue);        
    }
    
    const handledV2BitRateChange = (e) => {
        setV2BitRate(e.target.value)

        //set configurations
       const newValue = e.target.value;
       updateFormData('bps', newValue);  
    }
    
    const handleV2FPSChange = (e) => {
        setV2FPS(e.target.value)

        //set configurations
       const newValue = e.target.value;
       updateFormData('fps', newValue);  
    }
    
    const handleV2GOPChange = (e) => {
        setV2GOP(e.target.value)

        //set configurations
       const newValue = e.target.value;
       updateFormData('gop', newValue);  
    }
    
    const handleV2BmainStreamChange = (e) => {
        setV2BmainStream(e.target.value)

        //set configurations
        const newValue = e.target.value;
        updateFormData('bmainstream', newValue);  
      }
      
      const handleV2BFieldChange = (e) => {
        setV2BField(e.target.value)
        
        //set configurations
        const newValue = e.target.value;
        updateFormData('bfield', newValue);  
      }
      
      const handledV3BitRateChange = (e) => {
        setV3BitRate(e.target.value)
        
        //set configurations
        const newValue = e.target.value;
        updateFormData('bps', newValue);  
      }
      
      const handleV3FPSChange = (e) => {
        setV3FPS(e.target.value)
        
        //set configurations
        const newValue = e.target.value;
        updateFormData('fps', newValue);  
      }
      
      const handleV3GOPChange = (e) => {
        setV3GOP(e.target.value)
        
        //set configurations
        const newValue = e.target.value;
        updateFormData('gop', newValue);  
    }
    
    const handleV3BmainStreamChange = (e) => {
        setV3BmainStream(e.target.value)

        //set configurations
        const newValue = e.target.value;
        updateFormData('bmainstream', newValue);  
    }
   
    const handleV3BFieldChange = (e) => {
        setV3BField(e.target.value)

        //set configurations
        const newValue = e.target.value;
        updateFormData('bfield', newValue);  
    }

    return (
        <>
        
<div className="videoConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center gap-10">
    <h1 className="font-medium text-lg w-1/7">Video Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>

  <div className="videoConfigContent flex items-center gap-4 p-4">
    <label>Bit Rate:</label>
    <input
      name="bps"
      type="text"
      placeholder="Bit Rate"
      value={bitrate}
      onChange={handledBitRateChange}
      className="block p-2 border rounded"
    />
    
    <label>FPS:</label>
    <input
      type="text"
      placeholder="FPS"
      value={frameRate}
      onChange={handleFPSChange}
      className="block p-2 border rounded"
    />
    
    <label>GOP:</label>
    <input
      type="text"
      placeholder="GOP"
      value={gop}
      onChange={handleGOPChange}
      className="block p-2 border rounded"
    />
    
    <label htmlFor="bitRate">Bit Rate Type:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
    >
      <option value="constant">constant bitrate</option>
      <option value="variable">variable bitrate</option>
      <option value="fixed">fixed quality</option>
    </select>
    
    <label htmlFor="quality">Quality:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
      onChange={handledQualityChange}
    >
      <option value="Very High">Very High</option>
      <option value="High">High</option>
      <option value="Medium">Medium</option>
      <option value="Low">Low</option>
      <option value="Very Low">Very Low</option>
    </select>
  </div>

  <div className="flex items-center gap-4 p-4">
    <label htmlFor="resolution">Resolution:</label>
    <select id="resolutionLabel" name="resolution" className="p-2 border rounded">
      <option value="1280x720">1280x720</option>
      <option value="640x360">640x360</option>
      <option value="1920x1080">1920x1080</option>
    </select>
    
    <label>bmainstream:</label>
    <input
      type="text"
      placeholder="bmainstream"
      value={bmainStream}
      onChange={handleBmainStreamChange}
      className="block p-2 border rounded"
    />
    
    <label>bfield:</label>
    <input
      type="text"
      placeholder="bfield"
      value={bfield}
      onChange={handleBFieldChange}
      className="block p-2 border rounded"
    />
  </div>

  <div className="videoConfigContent flex items-center gap-4 p-4">
    <label>Bit Rate:</label>
    <input
      type="text"
      placeholder="Bit Rate"
      value={bitrate}
      onChange={handledV2BitRateChange}
      className="block p-2 border rounded"
    />
    
    <label>FPS:</label>
    <input
      type="text"
      placeholder="FPS"
      value={frameRate}
      onChange={handleV2FPSChange}
      className="block p-2 border rounded"
    />
    
    <label>GOP:</label>
    <input
      type="text"
      placeholder="GOP"
      value={gop}
      onChange={handleV2GOPChange}
      className="block p-2 border rounded"
    />
    
    <label htmlFor="bitRate">Bit Rate Type:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
    >
      <option value="constant">constant bitrate</option>
      <option value="variable">variable bitrate</option>
      <option value="fixed">fixed quality</option>
    </select>
    
    <label htmlFor="quality">Quality:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
    >
      <option value="Very High">Very High</option>
      <option value="High">High</option>
      <option value="Medium">Medium</option>
      <option value="Low">Low</option>
      <option value="Very Low">Very Low</option>
    </select>
  </div>

  <div className="flex items-center gap-4 p-4">
    <label htmlFor="resolution">Resolution:</label>
    <select id="resolutionLabel" name="resolution" className="p-2 border rounded">
      <option value="1280x720">1280x720</option>
      <option value="640x360">640x360</option>
      <option value="1920x1080">1920x1080</option>
    </select>
    
    <label>bmainstream:</label>
    <input
      type="text"
      placeholder="bmainstream"
      value={bmainStream}
      onChange={handleV2BmainStreamChange}
      className="block p-2 border rounded"
    />
    
    <label>bfield:</label>
    <input
      type="text"
      placeholder="bfield"
      value={bfield}
      onChange={handleV2BFieldChange}
      className="block p-2 border rounded"
    />
  </div>

  <div className="videoConfigContent flex items-center gap-4 p-4">
    <label>Bit Rate:</label>
    <input
      type="text"
      placeholder="Bit Rate"
      value={bitrate}
      onChange={handledV3BitRateChange}
      className="block p-2 border rounded"
    />
    
    <label>FPS:</label>
    <input
      type="text"
      placeholder="FPS"
      value={frameRate}
      onChange={handleV3FPSChange}
      className="block p-2 border rounded"
    />
    
    <label>GOP:</label>
    <input
      type="text"
      placeholder="GOP"
      value={gop}
      onChange={handleV3GOPChange}
      className="block p-2 border rounded"
    />
    
    <label htmlFor="bitRate">Bit Rate Type:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
    >
      <option value="constant">constant bitrate</option>
      <option value="variable">variable bitrate</option>
      <option value="fixed">fixed quality</option>
    </select>
    
    <label htmlFor="quality">Quality:</label>
    <select
      id="intervalLabel"
      name="bitrate"
      className="p-2 border rounded"
    >
      <option value="Very High">Very High</option>
      <option value="High">High</option>
      <option value="Medium">Medium</option>
      <option value="Low">Low</option>
      <option value="Very Low">Very Low</option>
    </select>
  </div>

  <div className="flex items-center gap-4 p-4">
    <label htmlFor="resolution">Resolution:</label>
    <select id="resolutionLabel" name="resolution" className="p-2 border rounded">
      <option value="1280x720">1280x720</option>
      <option value="640x360">640x360</option>
      <option value="1920x1080">1920x1080</option>
    </select>
    
    <label>bmainstream:</label>
    <input
      type="text"
      placeholder="bmainstream"
      value={bmainStream}
      onChange={handleV3BmainStreamChange}
      className="block p-2 border rounded"
    />
    
    <label>bfield:</label>
    <input
      type="text"
      placeholder="bfield"
      value={bfield}
      onChange={handleV3BFieldChange}
      className="block p-2 border rounded"
    />
  </div>
 </div>

        </>
      );
}

export default VideoConfig;