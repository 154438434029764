import { useEffect, useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";

function DateAndTime(){

  const[data, setData] = useState([])
  const[serverName, setServerName] = useState([])
  const[dateTime, setDateTime] = useState([])
  const [isDST, setIsDST] = useState(false)
  const [isAutoUpdate, setAutoUpdate] = useState(false)
  const [isOnvif, setOnvif] = useState(false)
  const [isEnable, setEnable] = useState(false)
  const [intervalDropdown, setIntervalDropdown] = useState('')

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)  
    if(data && data.appSettings){
      setServerName(data.appSettings.timeCfg.ntpserver)
      setDateTime(data.appSettings.timeCfg.time)
      setIsDST(data.appSettings.timeCfg.dstmode)
      setAutoUpdate(data.appSettings.timeCfg.autoupdate)
      setOnvif(data.appSettings.timeCfg.autoupdatetzonvif)
      setEnable(data.appSettings.timeCfg.ntpenable)
      setIntervalDropdown(data.appSettings.timeCfg.ntpinterval)
    }
  },[setIsDST, setAutoUpdate, setOnvif, setEnable, fetchedData, data])
 
  const handleDSTToogle = () => {
    const newValue = !isDST;
    setIsDST(newValue);
    updateFormData('dstmode', newValue ? 1 : 0);
  }
  
  const handleAutoUpdate = () => {
    const newValue = !isAutoUpdate;
    setAutoUpdate(newValue);
    updateFormData('autoupdate', newValue ? 1 : 0);
  }
  
  const handleOnviftToogle = () => {
    const newValue = !isOnvif;
    setOnvif(newValue);
    updateFormData('autoupdatetzonvif', newValue ? 1 : 0);
  }
  
  const handleEnableToogle = () => {
    setEnable(!isEnable)
    const newValue = !isEnable;
    setEnable(newValue);
    updateFormData('ntpenable', newValue ? 1 : 0);
  }
  
  const handleServerName = (e) => {
    setServerName(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('ntpserver', newValue);
  }
  
  const handleDateTime = (e) => {
    setDateTime(e.target.value)
    
    //set configurations
    const newValue = e.target.value;
    updateFormData('time', newValue);
  }
  const handleIntervalDropdownChange = (e) => {
    setIntervalDropdown(e.target.value)

    //set configurations
    const newValue = e.target.value;
    updateFormData('ntpinterval', newValue);
  }

    return (
      <>
       <div className="dateConfigs w-full p-10">
      <div className="mainHeadingDiv flex items-center">
        <h1 className="font-medium text-lg w-1/5">Date And Time Configurations</h1>
        <div className="headingDiv w-full border-t border-lightGray"></div>
      </div>
      <div className="timeConfigContent flex items-center flex-wrap gap-4 p-4">
          <div className="relative z-1 flex items-center">
                <span className="mr-2">DST: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isDST}
                    onChange={handleDSTToogle}
                    name="dstmode"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isDST ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isDST ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
        
          <div className="">
      <div className="relative z-1 flex items-center">
                <span className="mr-2">Auto Update: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isAutoUpdate}
                    onChange={handleAutoUpdate}
                    name="autoupdate"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isAutoUpdate ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isAutoUpdate ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
        </div>
        <div className="relative z-1 flex items-center">
                <span className="mr-2">Onvif Set TZ: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isOnvif}
                    onChange={handleOnviftToogle}
                    name="autoupdatetzonvif"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isOnvif ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isOnvif ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
        <div className="relative z-1 flex items-center">
                <span className="mr-2">Enable: </span>
                <label className="flex items-center rounded-full cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isEnable}
                    onChange={handleEnableToogle}
                    name="ntpenable"
                  />
                  <div
                    className={`w-10 h-6 rounded-full p-1 transition-all duration-300 ${
                      isEnable ? "bg-blue-400" : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`h-4 w-4 rounded-full bg-white transform ${
                        isEnable ? "translate-x-4" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
        <div className="">
          <label className="mb-2" htmlFor="interval">Interval:</label>
          <select
            id="intervalLabel"
            name="ntpinterval"
            value={intervalDropdown}
            onChange={handleIntervalDropdownChange}
            className="p-2 border rounded"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="12">12</option>
            <option value="24">24</option>
          </select>
        </div>
        <div className="">
          <label className="block mb-2">Date & Time:</label>
          <input
            type="text"
            name="time"
            value={dateTime}
            onChange={handleDateTime}
            placeholder="Date and time"
            className="p-2 border rounded"
          />
        </div>
        <div className="">
          <label className="block mb-2">Server Name:</label>
          <input
            type="text"
            name="ntpserver"
            value={serverName}
            onChange={handleServerName}
            placeholder="Server name"
            className="p-2 border rounded"
          />
        </div>
      </div>
    </div>
  </>
);
}

export default DateAndTime;