import { DataProvider } from "./DataContext";
import CameraConfigs from './components/cameraConfigs/cameraConfigs';
import DateAndTime from "./components/dateAndTimeConfigs/dateAndTime";
import DeviceInfo from "./components/deviceInfo/deviceInfo";
import DisplayConfigurations from "./components/display/displayConfiguration";
import ImageConfig from "./components/imageConfigs/imageConfig";
import NetworkConfig from "./components/networkConfigs/networkConfig";
import OSD from "./components/osdConfigs/osdConfigs";
import PTZConfigs from "./components/ptzConfigs/ptzConfigs";
import StreamConfig from './components/streamConfig/streamConfig';
import VideoConfig from "./components/videoConfig/videoConfig";
import CameraDetails from "./components/cameraDetails/cameraDetails";
import EmailConfigs from "./components/emailConfigs/emailConfig";
import { FormDataProvider } from "./postContext";

function Dashboard() {
  return (
    <DataProvider>
      <FormDataProvider>
        <div className="max-w-full max-h-full flex flex-wrap">
          <CameraConfigs className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <CameraDetails className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <StreamConfig className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <DateAndTime className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <EmailConfigs className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <VideoConfig className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <DisplayConfigurations className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <OSD className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <ImageConfig className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <PTZConfigs className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <DeviceInfo className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
          <NetworkConfig className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0" />
        </div>
      </FormDataProvider>
    </DataProvider>
  );
}

export default Dashboard;
