import React, { createContext, useContext, useState } from "react";

const dataContext = createContext()
export const useData = () => useContext(dataContext)

export const DataProvider = ({children}) => {
    const [fetchedData, setFetchedData] = useState(null)
    const updateFetchedData = (data) => {
        setFetchedData(data);
        }
    
    return (
      <dataContext.Provider value={{ fetchedData, updateFetchedData }}>
        {children}
      </dataContext.Provider>
    );
};