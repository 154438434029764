import { useEffect, useState } from "react";
import { useData } from "../../DataContext";
import { useFormData } from "../../postContext";

function DisplayConfigurations(){

  const[data, setData] = useState([])
  const[hue, setHue] = useState("")
  const[saturation, setSaturation] = useState("")
  const[brightness, setBrightness] = useState("")
  const[contrast, setContrast] = useState("")
  const[ir, setIR] = useState("")

  const{ fetchedData } = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)
    if(data && data.appSettings){
      setHue(data.appSettings.displayCfg.hue)
      setSaturation(data.appSettings.displayCfg.saturation)
      setBrightness(data.appSettings.displayCfg.brightness)
      setContrast(data.appSettings.displayCfg.contrast)
      setIR(data.appSettings.displayCfg.ircutmode)
    }
  },[fetchedData, data])

  const handleHueChange = (e) => {
    setHue(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('hue', newValue);
  }
  
  const handleSaturationChange = (e) => {
    setSaturation(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('saturation', newValue);
  }
  
  const handleBrightnessChange = (e) => {
    setBrightness(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('brightness', newValue);
  }
  
  const handleContrastChange = (e) => {
    setContrast(e.target.value)

    //set configurations
    const newValue = parseInt(e.target.value, 10);
    updateFormData('contrast', newValue);
  }

  const handleIRChange = (e) => {
    setIR(e.target.value)

     //set configurations
     const newValue = parseInt(e.target.value, 10);
     updateFormData('irCut', newValue);
  }

  return (
      <>
        <div className="displayConfigs w-full p-10">
  <div className="mainHeadingDiv flex items-center">
    <h1 className="font-medium text-lg w-1/6">Display Configurations</h1>
    <div className="headingDiv w-full border-t border-lightGray"></div>
  </div>
  
  <div className="displayConfigContent flex flex-wrap gap-4 p-4">
    <div className="">
      <label className="mb-2">Hue:</label>
      <input type="number" name="hue" placeholder="Hue" value={hue} onChange={handleHueChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Saturation:</label>
      <input type="number" name="saturation" placeholder="Saturation" value={saturation} onChange={handleSaturationChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Brightness:</label>
      <input type="number" name="brightness" placeholder="Brightness" value={brightness} onChange={handleBrightnessChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2">Contrast:</label>
      <input type="number" name="contrast" placeholder="Contrast" value={contrast} onChange={handleContrastChange} className="block p-2 border rounded" />
    </div>

    <div className="">
      <label className="mb-2" htmlFor="irCut">IRCut:</label>
      <select id="irCutLabel" name="ircutmode" value={ir} onChange={handleIRChange} className="block p-2 border rounded">
        <option value="1">Day & Night</option>
        <option value="2">Color</option>
        <option value="3">Black & White</option>
      </select>
    </div>
  </div>
</div>
      </>
    );
  }

export default DisplayConfigurations;