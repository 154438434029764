import { useData } from "../../DataContext";
import { useEffect, useState } from "react";
import { useFormData } from "../../postContext";
import axios from "axios";
import { Toaster, toast } from 'sonner';

function CameraDetails(){

  const[data, setData] = useState([])
  const[subscribe, setSubscribe] = useState("")
  const[status, setStatus] = useState("")

  const { fetchedData } = useData()
  const { formData, updateFormData } = useFormData();

  useEffect(() => {
    setData(fetchedData)  
    if (data && data.appSettings) {
      setSubscribe(data.appSettings.grUuid);
      setStatus(data.appSettings);
  }
  },[fetchedData, data])

  const handleSubscribeChange = (e) => {
    setSubscribe(e.target.value);

    //set configurations
    const newValue = e.target.value;
    updateFormData('grUuid', newValue);
  }
  
 

    return (
      <>
      <div className="cameraDetails p-10 mt-100 w-full">
        <div className="mainHeadingDiv flex items-center">
          <h1 className="font-medium text-lg w-1/6">Camera Details</h1>
          <div className="headingDiv w-full border-t border-lightGray"></div>
        </div>
        <div className="cameraDetailsContent flex flex-wrap gap-4 p-4">
          <div className="mb-4">
            <label className="block mb-2">Subscribed to:</label>
            <input
              type="text"
              name="grUuid"
              onChange={handleSubscribeChange}
              value={subscribe}
              placeholder="Subscribed to"
              className="p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Status:</label>
            <input
              type="text"
              placeholder="Status"
              className="p-2 border rounded"
            />
          </div>
        </div>
      </div>
    </>
    )
}

export default CameraDetails;